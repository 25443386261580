<template>
    <div class="animated fadeIn">
        <div class="wrapper">
            <div>
                <CRow>
                    <CCol lg="8">
                        <CCard>
                            <CCardHeader><font-awesome-icon icon="fa-barcode"/>  New Category
                                <font-awesome-icon style="color: #4caf50; " icon="info-circle" size="lg"/>
                            </CCardHeader>
                            <CCardBody>
                                <CForm @submit.prevent="createCategory">
                            <CRow>
                                <CCol lg="6" md="6" xl="6"> 
                                    <CInput 
                                        :value.sync="$v.category.color.$model"
                                        :lazy="false"
                                        :isValid="checkIfValid('color')"
                                        label="Color"
                                        type="color" 
                                        value="#ff0000"
                                        v-model="category.color"
                                    />
                                </CCol>
                            </CRow> 
                                    <CRow>
                                        <CCol lg="6">
                                            <CInput 
                                                :lazy="false"
                                                :value.sync="$v.category.category.$model"
                                                :isValid="checkIfValid('category')"
                                                label="Name" 
                                                type="text" 
                                                autocomplete="off" 
                                                v-tocapitalize
                                                v-nospecialcharacter
                                                invalidFeedback="Must be three(3) characters."
                                                v-model="category.category"
                                           >
                                            </CInput>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol col="12" class="text-right">
                                            <CButton 
                                                :disabled="!isValid"
                                                type="submit" 
                                                shape="pill" 
                                                color="primary"
                                            >
                                                <font-awesome-icon icon="save"/> Save
                                            </CButton>
                                        </CCol>
                                    </CRow>
                                </CForm>
                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>
            </div>
        </div>
    </div>
</template>
<script>
import { required,minLength } from "vuelidate/lib/validators"
export default {
    name: 'DocumentTemplateCategoryCreate',
    data () {
        return {
            category: {
                category: null,
                color: null,       
            },
        }
    },
    computed: {
        isValid () { return !this.$v.category.$invalid },
        isDirty () { return this.$v.category.$anyDirty },
    },
    validations: {
        category: {
            category: { required, minLength: minLength(3) },
            color: { required },
        }
    },
    created () {
        this.$Progress.start()
        this.validate();
        this.$emit('activeTab', 1);
        this.$Progress.finish()
    },
    methods: {
        validate: function () {
            this.$v.$touch()
        },
        checkIfValid: function (fieldName) {
            const field = this.$v.category[fieldName]
            if (!field.$dirty) {
                return null
            } 
            return !(field.$invalid || field.$model === '')
        },
        validateCategory:function () {
            if( !this.category.category || !this.category.color){
                return false;
            } 
            return true;
        },
        clearForm: function() {
            return {
                category: null,
                color: null,
            }
        },
        createCategory: function () {
            if(!this.validateCategory()) {
                this.$swal({
                    toast: true,
                    position: 'top-right',
                    timer: 3000,
                    icon: 'error',
                    title: 'Please fill up required fields.',
                    showConfirmButton: false,
                    timerProgressBar: true,
                });
                return;
            }
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                text: "You are trying to add new category.",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    this.$Progress.start()
                    let data = {
                        category: this.category.category,
                        color: this.category.color,
                    }
                    return axios.post('/drs/document-template-category/store', data, {validateStatus: () => true})
                    .then(response => {
                        if ( response.status == 200 ) {
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                text: `${this.category.category} has been added.`,
                            })
                            this.category = this.clearForm();
                            this.$v.$reset()
                            this.$v.$touch()
                            this.$Progress.finish()
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
        },
    },
}
</script>
